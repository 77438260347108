import React from 'react';
import { Avatar, Typography, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { useAuthState } from '../../context/auth';
import NavbarLink from './Navbar.Link';
import checkIsIFramed from '../../utils';
import AccountMenu from './AccountMenu';
import logo from '../../images/UBERFLIP-2020-RED.png';

const useStyles = makeStyles((theme: Theme) => ({
	avatar: {
		width: theme.spacing(6),
		height: theme.spacing(6),
	},
	brand: {
		cursor: 'default',
		display: 'inline-block',
		paddingLeft: '1.4rem',
		marginRight: '1rem',
		fontSize: '1.25rem',
		lineHeight: 'inherit',
		whiteSpace: 'nowrap',
		letterSpacing: '0.1em',
	},
}));

export default function Navbar() {
	const styles = useStyles();
	const userState = useAuthState();
	const location = useLocation();
	const isIFramed = checkIsIFramed();
	const noBrandkWidth = isIFramed ? '880px' : '750px';
	const noBrand = useMediaQuery(`(max-width:${noBrandkWidth})`);

	return (
		<nav className="navbar">
			{location.pathname !== '/login' && (
				<>
					<Avatar className={styles.avatar} alt="avatar" src={logo} />
					{!noBrand && (
						<Typography className="navbar__brand">
							Project Template
						</Typography>
					)}
					<NavbarLink to="/">HOME PAGE</NavbarLink>
					<NavbarLink to="/private">PRIVATE PAGE</NavbarLink>

					{!userState.authenticated && (
						<NavbarLink to="/login">LOGIN PAGE</NavbarLink>
					)}

					{!isIFramed && userState.authenticated && <AccountMenu />}
				</>
			)}
		</nav>
	);
}
