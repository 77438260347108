import React, { useState } from 'react';
import { Avatar, Typography, Paper, useMediaQuery, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { PasswordToggleInput, LoadingButton } from '../../components';
import { useAuthState, useAuthDispatch, loginUser } from '../../context/auth';
import { IHandleAuthSuccess } from './login.declarations';
import logo from '../../images/UBERFLIP-2020-RED.png';

const useStyles = makeStyles(() => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '45px',

		'& .MuiFormControl-root': {
			margin: '10px',
			width: '400px',
		},
		'& .MuiButtonBase-root': {
			margin: '20px',
		},
		'& .MuiButton-contained': {
			width: '400px',
		},
	},
	avatar: {
		width: '70px !important',
		height: '70px !important',
	},
	title: {
		paddingTop: '30px',
		paddingBottom: '30px',
		fontSize: '18px !important',
		textTransform: 'uppercase',
		letterSpacing: '0.1em !important',
	},
	credentials: {
		paddingTop: '8px',
	},
}));

const {
	REACT_APP_PASSPHRASE = '',
	REACT_APP_API_KEY = '',
	REACT_APP_API_SECRET = '',
} = process.env;

export default function LoginForm({ handleAuthSuccess }: IHandleAuthSuccess) {
	const styles = useStyles();
	const navigate = useNavigate();
	const dispatch = useAuthDispatch();
	const { loading, error } = useAuthState();
	const mobile = useMediaQuery('(max-width:580px)');

	const [passphrase, setPassphrase] = useState(REACT_APP_PASSPHRASE);
	const [key, setKey] = useState(REACT_APP_API_KEY);
	const [secret, setSecret] = useState(REACT_APP_API_SECRET);

	const handleLogin = async () => {
		const payload = {
			passphrase,
			APIKey: key,
			APISecret: secret,
		};

		try {
			await loginUser(dispatch, payload);
			handleAuthSuccess();
		} catch (e) {
			throw new Error(`An error occurred logging in, ${e}`);
		}
	};

	return (
		<form className={styles.form}>
			<Avatar
				className={styles.avatar}
				alt="logo"
				src={logo}
				onClick={() => navigate('/')}
			/>

			<Typography className={styles.title}>
				{mobile ? 'SE Template' : 'Sign in to SE Template'}
			</Typography>
			<Paper className={styles.form}>
				<PasswordToggleInput
					label="Passphrase"
					value={passphrase}
					onChange={(e) => setPassphrase(e.target.value)}
					helperText={error?.data?.message}
					displayHelper={error?.data?.source === 'passphrase'}
					disabled={loading}
					required
				/>
				<PasswordToggleInput
					label="API Key"
					value={key}
					onChange={(e) => setKey(e.target.value)}
					displayHelper={error?.data?.source === 'credentials'}
					disabled={loading}
					required
				/>
				<PasswordToggleInput
					label="API Secret"
					value={secret}
					onChange={(e) => setSecret(e.target.value)}
					helperText={error?.data?.message}
					displayHelper={error?.data?.source === 'credentials'}
					disabled={loading}
					required
				/>
				<LoadingButton loading={loading} onClick={handleLogin}>
					{loading ? 'Signing In...' : 'Sign In'}
				</LoadingButton>
				<Typography
					className={styles.credentials}
					color="textSecondary"
					variant="body2"
				>
					<Link
						className="text-link align-contents"
						color="inherit"
						href="https://app.uberflip.com/account/api_v2"
						target="_blank"
					>
						View REST API Credentials{' '}
						<OpenInNew
							fontSize="small"
							style={{ paddingLeft: '3px' }}
						/>
					</Link>
				</Typography>
			</Paper>
		</form>
	);
}
