import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateOutlet from './PrivateOutlet';
import LoginPage from '../pages/Login';
import HomePage from '../pages/Home';
import PrivatePage from '../pages/Private';

export default function AppRouter() {
	return (
		<Routes>
			<Route path="/" element={<HomePage />} />
			<Route path="/login" element={<LoginPage />} />
			<Route path="/private" element={<PrivateOutlet />}>
				<Route path="" element={<PrivatePage />} />
			</Route>
		</Routes>
	);
}
