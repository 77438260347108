import React from 'react';

export default function Index() {
	// test ci

	return (
		<>
			<h2 id="getting-started-with-solutions-engineering-full-stack-web-app-template">
				Getting Started with Solutions Engineering Full Stack{' '}
				<code>Typescript</code>
				Template
			</h2>
			<p>
				<a
					href="https://uberflip.atlassian.net/wiki/spaces/NODES/pages/2913665106/Project+Template+Overview"
					target="_blank"
					rel="noreferrer"
				>
					Reference the project template overview
				</a>
			</p>
		</>
	);
}
